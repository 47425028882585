<script>
import GlobalVue from '@root/helper/Global.vue';
export default {
  name: "NavBar",
  extends: GlobalVue,
  data(){
    return{
      notif:{}
    }    
  },
  mounted(){
    window.nav = this
  },
  methods:{
    logout(){
            this.page.logout()
        },
    hideLogo(){
      if($('#lerge-logo').hasClass( "hide-menu" )){
        $('#lerge-logo').removeClass( "hide-menu" )
        $('#small-logo').addClass( "hide-menu" )
        $('body').removeClass('mini-sidebar')
      }
      else{
        $('#lerge-logo').addClass( "hide-menu" )
        $('#small-logo').removeClass( "hide-menu" )
        $('body').addClass('mini-sidebar')
      }
    },
    showSidebar(){
      $("body").toggleClass("show-sidebar");
      $(".wrapper-overlay").toggle();
      $(".nav-toggler i").toggleClass("ti-menu ti-close");
    }
  }
};
</script>
<style type="text/css">
  .skin-default .topbar {
    background: #5a9426;
}
</style>

<template>
    <header class="topbar">
      <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <div class="navbar-header  text-center">
              <a class="navbar-brand" >
                  <img :src="assets('bo_images','logo-selosin-green.png')" height="80" alt="homepage" id="lerge-logo" class="dark-logo" :class="isMobile ? 'hide-menu':''" />
                  <img :src="assets('bo_images','logo-small.png')" height="80" alt="homepage" id="small-logo" class="dark-logo" :class="!isMobile ? 'hide-menu':''" />
                 </a>
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div class="navbar-collapse">
              <!-- ============================================================== -->
              <!-- toggle and nav items -->
              <!-- ============================================================== -->
              <ul class="navbar-nav">
                  <!-- This is  -->
                  <li class="nav-item" @click="showSidebar"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                  <li class="nav-item" @click="hideLogo"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                  <!-- ============================================================== -->
                  <!-- Search -->
                  <!-- ============================================================== -->
                  <!-- <li class="nav-item">
                      <form class="app-search d-none d-md-block d-lg-block">
                          <input type="text" class="form-control" placeholder="Search & enter">
                      </form>
                  </li> -->
              </ul>
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <ul class="navbar-nav ml-auto">
                  <!-- ============================================================== -->
                  <!-- Comment -->
                  <!-- ============================================================== -->
                
                 
                  <li class="nav-item">
                      <div class="dropdown">
                    <div class="navbar-dropdown dropdown-toggle u-dropdown hide-menu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <div class="mr-md-2"><VImg :src="assets('bo_images','user.jpg')" :lazy-src="uploader(user.bu_pic,'50x50c')" alt="user-img"  class="img-circle"></VImg></div>
                          <a href="javascript:void(0)" class="link d-none d-md-block">{{user.bu_full_name}}<span class="caret"></span></a>
                          <div class="dropdown-menu animated flipInY">
                              <div class="dropdown-divider"></div>
                              <router-link :to="{name:'BoProfile'}" class="dropdown-item"><i class="ti-settings"></i> Account Setting</router-link>
                              <div class="dropdown-divider"></div>
                              <a href="javascript:;" @click="logout()" class="dropdown-item"><i class="fa fa-power-off"></i> Logout</a>
                          </div>
                      </div>
                    </div>
                  </li>
              </ul>
          </div>
      </nav>
  </header>
</template>
